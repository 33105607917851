import React, { FunctionComponent, useContext } from 'react'
import { StyledAdverseEventForm } from './ReportAdverseEvent.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../../components/ClinButton'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { Row, Col } from 'react-grid-system'
import { ClinDividerHeading } from '../../../components/ClinDividerHeading'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { Trans, useTranslation } from 'react-i18next'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { AppContext } from '../../../context/app'
import { isAusGaUser } from '../../../constants'
import { Link } from 'react-router-dom'

interface IReportAdverseEventProps {}

export const ReportAdverseEvent: FunctionComponent<
  IReportAdverseEventProps
> = () => {
  const { t } = useTranslation()
  const { portfolioCountryCode, userRole } = useContext(AppContext)
  const AusCode = portfolioCountryCode === CountryAlphaCodes.Australia
  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(portfolioCountryCode, userRole)
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : {
              path: '/orders',
              name: t('orders:page_name')
            },
        {
          path: '/products/adverse-event',
          name: t('report_adverse_event:title')
        }
      ]}
    >
      {AusCode ? (
        <Row>
          <Col xs={12} lg={8}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('report_adverse_event:aus_users.title')}
            </ClinText>
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:aus_users.description')}
            </ClinText>
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:aus_users.description_2')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:aus_users.contact_text')}
            </ClinText>
            <div>
              <ClinGroup alignItems="center" justifyContent="flex-start">
                <ClinIcon
                  iconName={ClinIconPathName.Phone}
                  style={{ marginRight: 0 }}
                />
                <a
                  className="display-telephone"
                  href={`tel:${t(
                    'report_adverse_event:aus_users.raw_phone_number'
                  )}`}
                  style={{ margin: 0 }}
                  onClick={(event) => {
                    analyticsServiceSingleton.trackEvent(
                      AnalyticsEvent.ContactClicked,
                      { contactType: 'telephone' }
                    )
                  }}
                >
                  <ClinText
                    className={'telephone'}
                    as="span"
                    marginLeft={ClinTheme.space[2]}
                  >
                    {t('report_adverse_event:aus_users.telephone')}
                  </ClinText>
                </a>
              </ClinGroup>
            </div>
            <ClinSpacer height={ClinTheme.space[3]} />
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:aus_users.time_and_zone')}
            </ClinText>
            <div style={{ marginBottom: ClinTheme.space[3] }}>
              <ClinGroup alignItems="center" justifyContent="flex-start">
                <ClinIcon
                  iconName={ClinIconPathName.Mail}
                  style={{ marginRight: 0 }}
                />
                <a
                  className="raw-email"
                  href={`mailto:${t('report_adverse_event:aus_users.email')}`}
                  style={{ margin: 0 }}
                  onClick={(event) => {
                    analyticsServiceSingleton.trackEvent(
                      AnalyticsEvent.ContactClicked,
                      { contactType: 'email' }
                    )
                  }}
                >
                  <ClinText
                    className="email"
                    as="span"
                    color={ClinTheme.colors.primaryLight}
                    marginLeft={ClinTheme.space[2]}
                  >
                    {t('report_adverse_event:aus_users.email')}
                  </ClinText>
                </a>
              </ClinGroup>
            </div>

            <ClinSpacer height={ClinTheme.space[7]} />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} lg={8}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('report_adverse_event:title')}
            </ClinText>
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:supporting_text')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[2]} />
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              <Trans
                i18nKey="report_adverse_event:supporting_text_access_program"
                components={{
                  1: (
                    <Link to="../programs/access-programs">Access Program</Link>
                  )
                }}
              />
            </ClinText>
            <ClinSpacer height={ClinTheme.space[2]} />
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontSize={ClinTheme.fontSizes[3]}
            >
              {t('report_adverse_event:supporting_text_access_program_info')}
            </ClinText>

            <ClinSpacer height={ClinTheme.space[5]} />

            <ClinDividerHeading>
              <ClinText
                textTransform="uppercase"
                as="h5"
                color={ClinTheme.colors.white}
              >
                {t('report_adverse_event:section_header')}
              </ClinText>
            </ClinDividerHeading>

            <StyledAdverseEventForm>
              <FormTitle>{t('report_adverse_event:all_products')}</FormTitle>
              <a
                href="https://www.clinigengroup.com/media/2072/clinigen-adverse-event-report-form_20200415_v2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ClinButton as="span">
                  {t('report_adverse_event:download_form')}
                </ClinButton>
              </a>
            </StyledAdverseEventForm>
            <ClinSpacer height={ClinTheme.space[7]} />
          </Col>
        </Row>
      )}
    </ClinPageContentFrame>
  )
}

const FormTitle = ({ children }: { children: React.ReactNode }) => (
  <ClinText
    as={'h3'}
    variant={TypographyVariant.H5}
    marginTop={ClinTheme.space[0]}
    marginBottom={ClinTheme.space[0]}
    color={ClinTheme.colors.black}
    fontWeight={ClinTheme.fontWeights.normal}
  >
    {children}
  </ClinText>
)

import React, { FunctionComponent, useState } from 'react'
import { matchPath, Route, useLocation } from 'react-router'
import { useOnMount } from '../../utils/useOnMount'
import {
  cancelProgramsEnrolled,
  programsEnrolled
} from '../../services/ApiService'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { ProgramSummaryDto } from '../../types/swaggerTypes'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpinner } from '../ClinSpinner'
import { StyledSpinnerContainer } from '../ClinSpinner/ClinSpinner.styles'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { useAppContext } from '../../context/app/AppContext'
import { ProgramDetailContainer } from '../../pages/Programs/ProgramDetail'
import { IProductDetailProps } from '../../pages/Programs/ProgramDetail/ProgramDetailContainer'
import { ProgramDetailContainerOld } from '../../pages/Programs/ProgramDetail/ProgramDetailContainerOld'
import { getIsPageAccessibleFromCountryAndRole } from '../../utils/getIsPageAccessibleFromCountry'
import { NotFound } from '../../pages/NotFound'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'

interface IProgramsRouteParams {
  programId: string
}

interface IProgramsRouteProps {
  exact: boolean
  path: string
  forbidForCountryCodes?: string[]
  forbidRoleTypes?: string[]
}

export const ProgramsRoute: FunctionComponent<IProgramsRouteProps> = ({
  path,
  exact,
  forbidForCountryCodes,
  forbidRoleTypes
}) => {
  const { portfolioCountryCode, userRole } = useAppContext()
  const location = useLocation()
  const [enrolledPrograms, setEnrolledPrograms] = useState<
    ProgramSummaryDto[] | undefined
  >()
  const match = matchPath<IProgramsRouteParams>(location.pathname, {
    path: path,
    exact: exact
  })
  const { useFeatureFlag } = useFeatureFlags()
  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  const programId = match?.params.programId
  const isProgramEnrolled = () => {
    if (enrolledPrograms?.find((x) => x.programId.toString() === programId)) {
      return true
    }
    return false
  }
  const handleError = useErrorMessage('There was an error fetching programs.')
  useOnMount(() => {
    programId &&
      programsEnrolled({
        query: '',
        filter: {},
        pagination: {
          take: 100,
          skip: 0
        },
        sorting: {
          sortBy: 'programName',
          order: 'ASC'
        }
      })
        .then((response) => {
          setEnrolledPrograms(response?.data.result ?? [])
        })
        .catch((error) => {
          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
          handleError(error)
        })
    return () => {
      cancelProgramsEnrolled()
    }
  })

  return enrolledPrograms === undefined ? (
    <Container>
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        const updatedProps = props as unknown as IProductDetailProps

        if (
          userRole &&
          getIsPageAccessibleFromCountryAndRole(
            portfolioCountryCode,
            userRole,
            forbidForCountryCodes,
            forbidRoleTypes
          )
        ) {
          if (patientCentricFeatureFlag) {
            return (
              <ProgramDetailContainer
                {...updatedProps}
                isProgramEnrolled={isProgramEnrolled()}
              />
            )
          } else {
            return <ProgramDetailContainerOld {...updatedProps} />
          }
        } else {
          return <Route path={path} exact={true} component={NotFound} />
        }
      }}
    />
  )
}

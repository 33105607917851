import React, { FunctionComponent } from 'react'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  StyledClinTableOrderToggleWrapper,
  StyledPatientDetail,
  StyledTooltipFilterWrapper
} from './PatientDashboard.styles'
import {
  ClinPatientTableHeaderCell,
  ClinTable,
  ClinTableBodyCell,
  ClinTableRow
} from '../../../components/ClinTable/ClinTable'
import { ClinSpacer } from '../../../components/ClinSpacer/ClinSpacer'
import { Col, Row } from 'react-grid-system'
import {
  IPagination,
  getCurrentPage,
  getTotalPages
} from '../../../components/ClinPagination/ClinPagination.model'
import { ClinPagination } from '../../../components/ClinPagination'
import { PatientSummaryDto } from '../../../types/swaggerTypes'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { useTranslation } from 'react-i18next'
import { IColumn } from './PatientDashboardContainer'
import { NoPatients } from './NoPatients/NoPatients'
import { PatientsSearch } from '../PatientsSearch'
import { StyledFilter, StyledFilterWrapper } from '../PatientsFilter.styles'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinChip } from '../../../components/ClinChip'
import { ChipState } from '../../../components/ClinChip/ClinChip.styles'
import { PatientColumn, filterOptions, sortOption } from '../Patients.model'
import { ClinTableOrderToggle } from '../../../components/ClinTableOrderToggle'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  INewFeature,
  NewFeatureElements
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'

interface IMyPatientsProps {
  /** The array of physicians search results */
  patients?: PatientSummaryDto[]
  loadedPatients?: boolean
  /** Whether table is loading or not */
  isLoading?: boolean
  /** Columns to show on the physicians table */
  columns: IColumn[]
  /** Pagination */
  pagination?: IPagination
  /** User country */
  userCountry?: string
  query?: string
  selectedSortOption?: sortOption
  selectedFilterOptions?: string[]
  showTableInTab?: boolean
  newFeaturePatientNumber?: string
  /** The selected column */
  selectedSortColumn?: string
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType | string
  newFeaturesList?: { [id: string]: INewFeature }
  isNoPatientsAlternate?: boolean
  isProgramOpenForResupply?: boolean
  /** When table row clicked */
  handleRowClicked?: (
    physicianId: string | number,
    patientId: string | number,
    programId?: string | number,
    isStock?: boolean,
    orderNumber?: string,
    orderStatus?: string
  ) => void
  /** When pagination page is selected */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Select results per page */
  handlePageSizeChange?: (pageSize: number) => void
  handleOnSearch?: (query: string) => void
  handleSetSelectedFilterOption: (option: string) => void
  handleSelectedSortOption: (option: sortOption) => void
  /** Handle column sort toggle */
  handleToggleSort?: (columnName: string | undefined) => void
  handleCloseFeatureHighlight?: (feature: NewFeatureElements) => void
  displayNewFeature?: (feature: NewFeatureElements) => boolean | undefined
  displayNewFeatureGlowEffect?: (
    feature: NewFeatureElements
  ) => boolean | undefined
  canRedirectToOrderPage?: (
    orderStatus?: string,
    orderNumber?: string
  ) => boolean
  patientFilterChecked: boolean | null
}

const PatientDashboard: FunctionComponent<IMyPatientsProps> = ({
  patients,
  loadedPatients,
  isLoading,
  columns,
  pagination,
  selectedFilterOptions,
  showTableInTab,
  query,
  newFeaturePatientNumber,
  selectedSortColumn,
  selectedSortDirection,
  newFeaturesList,
  isNoPatientsAlternate,
  isProgramOpenForResupply,
  displayNewFeature,
  displayNewFeatureGlowEffect,
  handleRowClicked,
  handlePageClicked,
  handlePageSizeChange,
  handleOnSearch,
  handleSetSelectedFilterOption,
  handleToggleSort,
  handleCloseFeatureHighlight,
  canRedirectToOrderPage,
  patientFilterChecked
}) => {
  const doPatientsExist = patients && patients.length > 0

  const { t } = useTranslation()

  return (
    <StyledPatientDetail>
      <PatientsSearch
        handleOnChange={handleOnSearch}
        handleMyPatinets={handleSetSelectedFilterOption}
        handleCloseFeatureHighlight={handleCloseFeatureHighlight}
        displayNewFeature={displayNewFeature}
        displayNewFeatureGlowEffect={displayNewFeatureGlowEffect}
        showTableInTab={showTableInTab}
        newFeaturesList={newFeaturesList}
        initialQuery={query}
        patientFilterChecked={patientFilterChecked}
      ></PatientsSearch>

      <ClinPageContentFrame hideBreadcrumbs={true}>
        {!showTableInTab && (
          <>
            <ClinSpacer height={26} />
            <StyledFilterWrapper>
              <StyledFilter>
                <>
                  {newFeaturesList &&
                    displayNewFeature &&
                    displayNewFeature(NewFeatureElements.PatientsFilter) && (
                      <StyledTooltipFilterWrapper>
                        <ClinNewFeatureTooltipContainer
                          feature={
                            newFeaturesList[NewFeatureElements.PatientsFilter]
                          }
                          openTooltipInitially={
                            !newFeaturesList[NewFeatureElements.PatientsFilter]
                              ?.isCancelled
                          }
                          showFeatureFromTable={true}
                          disableDismiss={true}
                          total={4}
                          current={3}
                        ></ClinNewFeatureTooltipContainer>
                      </StyledTooltipFilterWrapper>
                    )}
                  <ClinText
                    marginRight={`${ClinTheme.space[3]}px`}
                    lineHeight={ClinTheme.lineHeights.small}
                    color={'#280172'}
                    variant={TypographyVariant.H5}
                  >
                    {t('patient_detail:filter_by')}
                  </ClinText>
                </>

                {filterOptions &&
                  filterOptions.map((filter, index) => (
                    <ClinChip
                      showGlowEffect={
                        newFeaturesList &&
                        displayNewFeatureGlowEffect &&
                        displayNewFeatureGlowEffect(
                          NewFeatureElements.PatientsFilter
                        )
                      }
                      key={index}
                      state={
                        selectedFilterOptions &&
                        selectedFilterOptions.findIndex(
                          (i) => i === filter.optionId
                        ) > -1
                          ? ChipState.Active
                          : ChipState.Default
                      }
                      isInteractive={true}
                      onClick={() => {
                        if (
                          newFeaturesList &&
                          newFeaturesList[NewFeatureElements.PatientsFilter]
                        ) {
                          handleCloseFeatureHighlight &&
                            handleCloseFeatureHighlight(
                              NewFeatureElements.PatientsFilter
                            )
                        }
                        handleSetSelectedFilterOption(filter.optionId)
                      }}
                    >
                      {t(filter.optionValue)}
                    </ClinChip>
                  ))}
              </StyledFilter>
            </StyledFilterWrapper>
          </>
        )}
        {!showTableInTab ? (
          <ClinSpacer height={24} hasBorder={true} />
        ) : (
          <ClinSpacer height={5} />
        )}
        <Row>
          <Col sm={12} style={showTableInTab ? { padding: 0 } : {}}>
            {isLoading || !loadedPatients ? (
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            ) : doPatientsExist ? (
              <ClinTable
                borderColapseSeparate={true}
                borderSpacing="0 8px"
                padding="0px 2px 0px 2px"
                tableHeader={() =>
                  columns.map((column: IColumn, index: number) => {
                    return (
                      <ClinPatientTableHeaderCell
                        key={index}
                        width={column.width}
                        background={ClinTheme.colors.lightGrey}
                        color={showTableInTab ? ClinTheme.colors.darkGrey : ''}
                      >
                        {column.isSortable ? (
                          <StyledClinTableOrderToggleWrapper>
                            <ClinTableOrderToggle
                              sortDirection={
                                selectedSortColumn ===
                                (column.name as PatientColumn)
                                  ? (selectedSortDirection as SortDirectionType)
                                  : SortDirectionType.None
                              }
                              onClick={(event) =>
                                handleToggleSort &&
                                handleToggleSort(column.name)
                              }
                            >
                              {column.title.toUpperCase()}
                            </ClinTableOrderToggle>
                          </StyledClinTableOrderToggleWrapper>
                        ) : (
                          <StyledClinTableOrderToggleWrapper>
                            <ClinTableOrderToggle hideSortIcon={true}>
                              {column.title.toUpperCase()}
                            </ClinTableOrderToggle>
                          </StyledClinTableOrderToggleWrapper>
                        )}
                      </ClinPatientTableHeaderCell>
                    )
                  })
                }
                className="TableOverflowVisible"
              >
                {patients?.map((patient: PatientSummaryDto, rowIndex) => {
                  return (
                    <ClinTableRow
                      style={
                        patient.patientNumber === newFeaturePatientNumber
                          ? {
                              pointerEvents: 'auto',
                              zIndex: 5,
                              position: 'relative'
                            }
                          : {}
                      }
                      disabled={
                        patient.stockOrderFlag &&
                        canRedirectToOrderPage &&
                        !canRedirectToOrderPage(
                          patient.patientOrderStatus,
                          patient.orderNumber
                        )
                      }
                      background={ClinTheme.colors.white}
                      key={rowIndex}
                      onRowClick={() =>
                        handleRowClicked &&
                        patient.physicianId &&
                        handleRowClicked(
                          patient.physicianId,
                          patient.patientId,
                          patient.programId,
                          patient.stockOrderFlag,
                          patient.orderNumber,
                          patient.patientOrderStatus
                        )
                      }
                      onRowEnter={() =>
                        handleRowClicked &&
                        patient.physicianId &&
                        handleRowClicked(
                          patient.physicianId,
                          patient.patientId,
                          patient.programId,
                          patient.stockOrderFlag,
                          patient.orderNumber,
                          patient.patientOrderStatus
                        )
                      }
                    >
                      {columns.map((column: IColumn, index: number) => (
                        <ClinTableBodyCell
                          key={index}
                          style={{ padding: '11px 24px 11px 16px' }}
                        >
                          {column.renderValue(patient, rowIndex)}
                        </ClinTableBodyCell>
                      ))}
                    </ClinTableRow>
                  )
                })}
              </ClinTable>
            ) : (
              <NoPatients
                isAlternate={isNoPatientsAlternate}
                isResupply={isProgramOpenForResupply}
              />
            )}
          </Col>
        </Row>
        <ClinSpacer />
        <Row>
          <Col>
            {!isLoading &&
            doPatientsExist &&
            pagination &&
            getTotalPages(pagination) ? (
              <ClinPagination
                showTabsPagination={showTableInTab}
                showLeftSpacer={showTableInTab}
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) =>
                  handlePageClicked && handlePageClicked(pageIndex)
                }
                handlePageSizeChange={handlePageSizeChange}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </ClinPageContentFrame>
    </StyledPatientDetail>
  )
}

export default PatientDashboard

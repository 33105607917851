export enum OrderStatus {
  Submitted = 'Submitted',
  Processing = 'Processing',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
  OnHold = 'On_Hold',
  On_Hold = 'On Hold',
  Delivered = 'Delivered',
  InTransit = 'In_Transit',
  OutForDelivery = 'Out_for_delivery',
  FailedAttempt = 'Failed_attempt'
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  StyledOpaHeaderStepper,
  IOpaHeaderStepperStyles,
  StyledStepperItem,
  StyledStepperLine,
  StyledStepperItemsWrapper,
  StyledStepperButtonWrapper,
  StyledSaveButton,
  StyledStepperItems
} from './OpaHeaderStepper.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'
import { TypographyVariant } from '../ClinText/ClinText.styles'
import { ClinButton } from '../ClinButton'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'

interface IOpaHeaderStepperProps extends IOpaHeaderStepperStyles {
  currentStep: number
  totalSteps: number
  textContentArray: string[]
  poNumber?: string
  programName?: string
  programId? : string
  handleCancelButton: () => void
  handleSaveAndCloseButton: (programName? :string, programId?: string, poNumber?: string ) => void
  isParentSaving?: boolean
  isParentCancelling?: boolean
}

export const OpaHeaderStepper: FunctionComponent<IOpaHeaderStepperProps> = ({
  currentStep,
  totalSteps,
  textContentArray,
  poNumber,
  isParentSaving,
  isParentCancelling,
  programName,
  programId,
  handleCancelButton,
  handleSaveAndCloseButton
}) => {
  const { t } = useTranslation()
  const steps = []

  const [isSaving, setIsSaving] = useState<boolean>(isParentSaving ?? false)
  const [isCancelling, setIsCancelling] = useState<boolean>(
    isParentCancelling ?? false
  )

  const { useFeatureFlag } = useFeatureFlags()
  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  useEffect(() => {
    setIsSaving(isParentSaving ?? false)
    setIsCancelling(isParentCancelling ?? false)
  }, [isParentCancelling, isParentSaving])

  for (let i = 0; i < totalSteps; i++)
    i < currentStep ? steps.push(true) : steps.push(false)
  let stepLength = steps.length

  return (
    <StyledOpaHeaderStepper>
      <StyledStepperItemsWrapper>
        <StyledStepperItems>
          {steps.map((item, index) => {
            return (
              <>
                {item ? (
                  <StyledStepperItem>
                    <ClinIcon
                      viewBox="0 0 20 20"
                      iconSize="20px"
                      iconName={ClinIconPathName.WhiteCheckboxPurpleCircle}
                      iconFill={ClinTheme.colors.primaryMid}
                    ></ClinIcon>
                    <ClinText variant={TypographyVariant.H5}>
                      {textContentArray[index]}
                    </ClinText>
                    {index < stepLength - 1 ? <StyledStepperLine /> : null}
                  </StyledStepperItem>
                ) : (
                  <StyledStepperItem>
                    <ClinIcon
                      viewBox="0 0 20 20"
                      iconSize="20px"
                      iconName={ClinIconPathName.EmptyPurpleCircle}
                      iconFill={ClinTheme.colors.primaryMid}
                    />
                    <ClinText variant={TypographyVariant.H5}>
                      {textContentArray[index]}
                    </ClinText>
                    {index < stepLength - 1 ? <StyledStepperLine /> : null}
                  </StyledStepperItem>
                )}
              </>
            )
          })}
        </StyledStepperItems>
        {patientCentricFeatureFlag && (
          <StyledStepperButtonWrapper>
            <StyledSaveButton
              variant="purpleNarrow"
              onClick={() => {
                !isCancelling &&
                  !isSaving &&
                  handleSaveAndCloseButton &&
                  handleSaveAndCloseButton(programName,programId,poNumber)
                setIsSaving(true)
              }}
            >
              {t('common:buttons.save_and_close')}
            </StyledSaveButton>
            <ClinButton
              variant="purpleSecondaryNarrow"
              onClick={() => {
                !isCancelling &&
                  !isSaving &&
                  handleCancelButton &&
                  handleCancelButton()
                setIsCancelling(true)
              }}
            >
              {t('common:buttons.delete_form')}
            </ClinButton>
          </StyledStepperButtonWrapper>
        )}
      </StyledStepperItemsWrapper>
    </StyledOpaHeaderStepper>
  )
}

import React, { FunctionComponent, useEffect, useState } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpinner } from '../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ProgramCatalogDto } from '../../types/swaggerTypes'
import { truncateString } from '../../utils/truncateString'
import { Col, Row } from 'react-grid-system'
import { StyledIFrame } from './PatientAccessForm.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinText } from '../../components/ClinText'
import { StyledLinkUnderline } from '../../components/StyledLink'
import { Trans, useTranslation } from 'react-i18next'
import { OpaHeaderStepper } from '../../components/OpaHeaderStepper'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { OpaNavigationPrompt } from '../../components/OpaNavigationPrompt'
interface IPatientAccessFormProps {
  /** If we are loading or not */
  isLoading: boolean
  /** Program */
  program?: ProgramCatalogDto
  /** The URL to the OPA */
  opaUrl?: string
  /** The dynamic height of the iframe */
  height: number
  /** If the OPA is read only */
  isReadOnly: boolean
  /** Back button title */
  backButtonTitle: string
  /** User country */
  userCountry?: string
  patientCentricFeatureFlag: boolean | undefined
  isSaving: boolean
  isCancelling: boolean
  canNavigateAway: boolean
  /** Callback when user click on back button */
  handleGoBack: () => void
  /** Callback when iframe container is loaded  */
  handleIFrameLoaded?: () => void

  handleCancelButton: () => void
  handleSaveAndCloseButton: (
    programName?: string,
    programId?: string,
    poNumber?: string
  ) => void
}

export const PatientAccessForm: FunctionComponent<IPatientAccessFormProps> = ({
  isLoading,
  isSaving,
  isCancelling,
  program,
  opaUrl,
  height,
  isReadOnly,
  backButtonTitle,
  patientCentricFeatureFlag,
  canNavigateAway,
  handleCancelButton,
  handleSaveAndCloseButton,

  handleGoBack,
  handleIFrameLoaded
}) => {
  const { t } = useTranslation()
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)
  const programName: string = program?.programName ?? ''
  
  const [shouldShowOPAError,setShouldShowOPAError] = useState<boolean>(false)

  useEffect(() => {
    const blockClick = (event:any) => {
      event.preventDefault()
      event.stopPropagation()
      setShouldShowOPAError(true)
    }

    const elements =  Array.from(document.getElementsByClassName("block-institute-picker"))
    let logOut = Array.from(document.getElementsByClassName("block-log-out"))
    logOut && logOut.length > 0 && elements.push(...logOut)
    elements.forEach(element => {
      element.addEventListener('click', blockClick)
    })

    // Cleanup: Remove event listeners when component unmounts
    return () => {
      for (let element of elements) {
        element.removeEventListener('click', blockClick)
      }
    }
  }, [])

  if (isLoading || !program) {
    return (
      <StyledSpinnerContainer>
        <ClinSpinner size={ClinTheme.space[7]} />
      </StyledSpinnerContainer>
    )
  }
  return (
    <>
      {patientCentricFeatureFlag && !isReadOnly && (
        <OpaHeaderStepper
          currentStep={1}
          totalSteps={2}
          handleCancelButton={handleCancelButton}
          handleSaveAndCloseButton={handleSaveAndCloseButton}
          isParentSaving={isSaving}
          isParentCancelling={isCancelling}
          textContentArray={[
            t('opa_checkout:checkout_header_step_one'),
            t('opa_checkout:checkout_header_step_two')
          ]}
          programName={programName}
          programId={program?.program?.projectId?.toString()}
        ></OpaHeaderStepper>
      )}
      <OpaNavigationPrompt
        when={!canNavigateAway}
        handleSaveEvent={handleSaveAndCloseButton}
        manualTrigger={shouldShowOPAError}
        setShouldShowOPAError = {setShouldShowOPAError}
      ></OpaNavigationPrompt>
        <ClinPageContentFrame
          className="patient-access-form"
          hideBreadcrumbs={patientCentricFeatureFlag}
          crumbs={[
            { path: '/orders', name: t('orders:page_name') },
            {
              name: t('access_programs:title'),
              path: '/programs/access-programs'
            },
            {
              name: truncateString(programName),
              path: `/programs/access-programs/${program?.projectId}`
            },
            { name: t('patient_access_form:title'), path: '/' }
          ]}
        >
          <ClinSpacer
            height={ClinTheme.space[patientCentricFeatureFlag ? 9 : 4]}
          />
          {!patientCentricFeatureFlag && (
            <>
              <ClinButton
                onClick={() => handleGoBack && handleGoBack()}
                style={{ marginTop: ClinTheme.space[3] }}
                variant="link"
              >
                <ClinIcon
                  style={{ marginRight: ClinTheme.space[2] }}
                  iconSize={ClinTheme.fontSizes[3]}
                  iconName={ClinIconPathName.ArrowLeft}
                />
                {backButtonTitle}
              </ClinButton>
              <ClinSpacer height={ClinTheme.space[5]} hasBorder={true} />
            </>
          )}
          <Row align="center">
            <Col>
              <>
                {!patientCentricFeatureFlag && !isReadOnly && (
                  <ClinAnnounceBar
                    mode={AnnounceMode.Information}
                    title={t('patient_access_form:resupply_order_info_title')}
                    message=""
                    marginBottom={ClinTheme.space[3]}
                  >
                    <ClinText color={ClinTheme.colors.primaryMid}>
                      <Trans
                        i18nKey="patient_access_form:resupply_order_info_description"
                        components={[
                          <StyledLinkUnderline
                            key="link"
                            to={'/support/video-guide/663899266'}
                          />
                        ]}
                      >
                        <></>
                      </Trans>
                    </ClinText>
                  </ClinAnnounceBar>
                )}
                {
                  isSaving && (<StyledSpinnerContainer>
                                <ClinSpinner size={ClinTheme.space[7]} />
                              </StyledSpinnerContainer>)}
                {opaUrl && height ? (
                  <StyledIFrame
                    title="OPA"
                    id="opa-Iframe"
                    src={opaUrl}
                    height={height}
                    onLoad={handleIFrameLoaded}
                    width={'100%'}
                    shouldHide={isSaving}
                  />
                ) : (
                  <StyledSpinnerContainer>
                    <ClinSpinner size={ClinTheme.space[7]} />
                  </StyledSpinnerContainer>
                )}
              </>
            </Col>
          </Row>
        </ClinPageContentFrame>
    </>
  )
}
